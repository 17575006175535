import { RefObject, useEffect, useRef } from 'react';
import Debug from 'debug';

const debug = Debug('songwhip/useKeyboard');

/**
 * Simply keyboard tab navigation when within a UI context (eg. moda/dialog)
 *
 * - Locks focus to the elements inside the modal
 * - Allows hook into `[esc]` key with `onEscape()` callback
 *
 * NOTE: Be careful to ensure all props and deps are memoized otherwise it'll
 * cause needless binding and rebinding of event handlers on each react render.
 */
const useKeyboard = (
  {
    rootElRef,
    onEscape,
    active = true,
    restoreFocusOnUnmount = true,
    restoreFocusOnEscape = false,
  }: {
    rootElRef: RefObject<HTMLElement>;
    active?: boolean;
    restoreFocusOnEscape?: boolean;
    restoreFocusOnUnmount?: boolean;
    onEscape: (params: { originalFocusedEl: HTMLElement | null }) => void;
  },
  deps: any[] = []
) => {
  // we have to grab the originalFocusedEl on first render as if there's
  // an element inside scope that has `autofocus` the browser can set this
  // as the `activeElement` before our onMount logic runs.
  const originalFocusedElRef = useRef<HTMLElement | null>(getActiveEl());
  const activeRef = useRef(active);

  useEffect(() => {
    const prevActive = activeRef.current;
    activeRef.current = active;

    if (!active) return;

    debug('originalFocusedEl', originalFocusedElRef.current);

    const becameActive = !prevActive;

    if (becameActive) {
      const activeEl = getActiveEl();
      originalFocusedElRef.current = activeEl;
      debug('update originalFocusedEl', activeEl);
    }

    // on unmount re-focus the element that was in-focus when this hook mounted
    return () => {
      const originalFocusedEl = originalFocusedElRef.current;

      if (restoreFocusOnUnmount) {
        debug('restore focus to', originalFocusedEl);
        originalFocusedEl?.focus();
      }
    };
  }, [active]);

  useEffect(() => {
    const rootEl = rootElRef.current;

    if (!rootEl) return;
    if (!active) return;

    debug('setup', {
      rootEl,
    });

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        const activeEl = getActiveEl();
        const shiftKeyPressed = event.shiftKey;

        // fetch the list of focusable elements inside rootEl on demand
        // so that if the dom changes we don't have a stale list
        const focusableEls = getFocusableEls(rootEl);

        const lastEl = focusableEls[focusableEls.length - 1];
        const firstEl = focusableEls[0];
        const isLast = activeEl === lastEl;
        const isFirst = activeEl === firstEl;

        debug('on tab', {
          activeEl,
          isFirst,
          isLast,
          focusableEls,
        });

        if (isLast && !shiftKeyPressed) {
          event.preventDefault();
          firstEl?.focus();
        } else if (isFirst && shiftKeyPressed) {
          event.preventDefault();
          lastEl?.focus();
        }
      } else if (event.key === 'Escape') {
        debug('on escape');

        const originalFocusedEl = originalFocusedElRef.current;

        if (restoreFocusOnEscape) {
          originalFocusedEl?.focus();
        }

        onEscape({
          originalFocusedEl: originalFocusedElRef.current,
        });
      }
    };

    rootEl.addEventListener('keydown', onKeyDown);

    const focusableEls = getFocusableEls(rootEl);
    const activeElAlreadyInScope = focusableEls.includes(getActiveEl());

    // COMPLEX: when the active element is not already in scope we should
    // set it to the first focusable element in the scope. If dependencies
    // change then this use-effect handler can run multiple times while
    // the useKeyboard hook is mounted. Without this guard we can end up
    // shifting the focus to another element while the user is typing.
    if (!activeElAlreadyInScope) {
      // find the first focusable element with the `autofocus` attr,
      // this is a markup hint that this element should be focused on render
      const elWithAutofocus = focusableEls.find((el) => el.autofocus);

      const firstElToFocus = (elWithAutofocus || focusableEls[0]) as
        | HTMLElement
        | undefined;

      // sometimes the browser will focus on element that have the `autofocus` attr,
      // but we still do this programmatically to cover all cases
      firstElToFocus?.focus();
    }

    return () => {
      debug('remove listeners');
      rootEl.removeEventListener('keydown', onKeyDown);
    };
  }, [...deps, rootElRef, active]);
};

const getFocusableEls = (rootEl: HTMLElement) =>
  [].slice
    .call(rootEl.querySelectorAll(`input,button,a,[tabIndex]`))
    .filter(
      (el: HTMLButtonElement | HTMLInputElement) =>
        (el.tabIndex ?? 0) > -1 && !el.disabled && !el.hidden
    );

const getActiveEl = () =>
  process.browser ? (document.activeElement as HTMLElement) : null;

export default useKeyboard;
